@import '../../../../styles/app.scss';
:global {
  @import '../../../../../node_modules/react-datetime/css/react-datetime';
}

.root {
  :global {
    .tab-content {
      overflow: visible;
      background-color: transparent;
    }
  }

  .formWizard {
    :global {
      .progress {
        margin-top: 6px;
        margin-bottom: 10px;
      }

      .form-group:last-child {
        margin-bottom: 0;
      }

      .pager {
        display: flex;
        margin: 18px 0;
        flex-direction: row;
        justify-content: space-between;

        .disabled button {
          cursor: not-allowed;
          opacity: 0.65;
        }
      }

      #datepicker .input-group {
        width: 100%;
        display: flex !important;

        .form-control {
          border-bottom-right-radius: 0.25rem;
          border-top-right-radius: 0.25rem;
        }

        .input-group-addon {
          display: none;
        }
      }
    }
  }

  .wizardNavigation {
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;

    :global {
      .active {
        background: $primary;

        &:hover {
          color: $white;
        }
      }
    }

    li {
      text-align: center;
    }

    a {
      padding: $spacer;
      color: $text-color;
      background: $bg-addition;
      min-width: 140px;
      font-family: 'Poppins' !important;
      font-size: 14px;
      font-style: normal;
      line-height: 20px;
      strong {
        font-weight: $font-weight-semi-bold;
      }

      small {
        font-weight: $font-weight-thin;
      }
    }
  }

  .stepBody {
    border-radius: $border-radius;
  }
}

.dropzone {
  border: 1px dotted var(--primary);
  padding: 2rem;
}
.optionSelected {
  margin-bottom: 5px;
}
.filterContainer {
  max-width: 500px;
  overflow-x: scroll;
  white-space: nowrap;
  font-size: 14px;
}
.legalHoldName {
  overflow-x: scroll;
  white-space: nowrap;
  font-size: 14px;
}
.legalHoldDescription {
  overflow-x: scroll;
  white-space: nowrap;
  font-size: 14px;
}
.stats {
  font-size: 18px;
  margin-bottom: 7px;
  text-align: center;
}
.mainContainer {
  margin-top: 15px;
  display: flex;
  width: 100%;
}

.custodiansContainer {
  width: 40%;
}
.title {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
  margin-right: 5px;
}

.custodianNames {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 75px;
  width: 100%;
  margin-bottom: 5px;
}
.custodiansList {
  text-align: start;
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}
.confirmationButtons {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.custodianName {
  display: flex;
  width: 100%;
  word-break: break-word;
  flex-wrap: wrap;
  margin-bottom: 3.5px;
}
.results {
  border: 1px solid $primary;
  margin-bottom: 10px;
  margin-top: 10px;
  height: 100%;
}
.resultStatsContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;
  margin-right: 10%;
  margin-left: 12%;
}
.statsLabel {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  margin-right: 5px;
}
.resultHeader {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 5px;
  margin-right: 10%;
  margin-left: 10%;
  margin-top: 10px;
}
.calculatingText {
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  justify-content: center;
}
.errorMessage {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 10px;
  color: #db2a34;
  font-size: 11.2px;
}
.expandedRowContainer {
  width: 700px;
  min-height: 30px;
  max-height: 100px;
  display: flex;
  margin-bottom: 10px;
  margin-top: 0px;
  overflow-y: scroll;
}
@media (min-width: 2560px) {
  .expandedRowContainer {
    width: 700px;
    min-height: 40px;
    max-height: 100px;
    display: flex;
    margin-bottom: 10px;
    margin-top: 0px;
    overflow-y: scroll;
  }
}

.parentRow {
  background-color: var(--expand-row-bg-color) !important;
  cursor: pointer !important;
}

.expandedRowHeader {
  padding-top: 10px;
  margin-left: 10px;
  font-weight: bold;
  font-size: 12px;
}

.internalCustodiansTable {
  width: 100%;
}
.internalCustodiansTable tbody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 290px;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: start;
}
.internalCustodiansTable tr {
  display: flex;
  justify-content: space-between;
}

.internalCustodiansTable tr td:first-child {
  width: 20%;
}
.internalCustodiansTable tr th:first-child {
  width: 20%;
  padding-left: 34px;
  padding-right: 0px !important;
}
.internalCustodiansTable tr td:nth-child(2) {
  width: 45%;
}
.internalCustodiansTable tr th:nth-child(2) {
  width: 45%;
}
.internalCustodiansTable tr td:nth-child(3) {
  width: 25%;
}
.internalCustodiansTable tr th:nth-child(3) {
  width: 25%;
}
.internalCustodiansTable tr td:nth-child(4) {
  width: 10%;
  display: flex;
  text-align: center;
  margin-right: 10px;
}
.internalCustodiansTable tr th:nth-child(4) {
  width: 10%;
  margin-right: 10px;
}

.allCustodiansTable {
  width: 100%;
}
.allCustodiansTable tbody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 290px;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: start;
}
.allCustodiansTable tr {
  display: flex;
  justify-content: space-between;
}

.allCustodiansTable tr td:first-child {
  width: 20%;
}
.allCustodiansTable tr th:first-child {
  width: 20%;
  padding-left: 34px;
  padding-right: 0px !important;
}
.allCustodiansTable tr td:nth-child(2) {
  width: 10%;
}
.allCustodiansTable tr th:nth-child(2) {
  width: 10%;
}
.allCustodiansTable tr td:nth-child(3) {
  width: 35%;
}
.allCustodiansTable tr th:nth-child(3) {
  width: 35%;
}
.allCustodiansTable tr td:nth-child(4) {
  width: 25%;
  display: flex;
  text-align: center;
  margin-right: 10px;
}
.allCustodiansTable tr th:nth-child(4) {
  width: 25%;
  margin-right: 10px;
}
.allCustodiansTable tr td:nth-child(5) {
  width: 10%;
  display: flex;
  text-align: center;
  margin-right: 10px;
}
.allCustodiansTable tr th:nth-child(5) {
  width: 10%;
  margin-right: 10px;
}

.icon {
  width: 100%;
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: center;
}
.custodianData {
  word-break: break-word;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.customRowClass td {
  color: #f4f4f580 !important;
}

.customRowCursor td:last-child {
  cursor: pointer !important;
}

.navLink {
  background: transparent !important;
}
.tabPane {
  background: rgba(0, 0, 0, 0.24);
}
.paginationNext {
  background-color: initial;
  border: 1px solid #474d84;
  color: white !important;
  height: 33px;
  width: 33px;
  margin: 0px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins' !important;
}

.activeLinkClass {
  background-color: #474e80 !important;
  border: 1px solid #474d84;
  color: white !important;
  height: 33px;
  width: 33px;
  margin: 0px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins' !important;
}

.dropdownItem {
  background: #05050c !important;
  height: 25px;
  border: none;
  &:hover {
    background-color: var(--primary-button-bg) !important;
  }
}

.identitySmtpBox {
  padding: 1px;
  font-size: 10px;
  cursor: pointer;
  min-width: 30px;
  text-align: center;
  border: 1px solid #2d8515;
  color: #2d8515;
}
.identityX500Box {
  padding: 1px;
  font-size: 10px;
  cursor: pointer;
  min-width: 30px;
  text-align: center;
  border: 1px solid #3978f6;
  color: #3978f6;
}

.customAddress {
  font-weight: bold;
  color: #3978f6;
}

.pageNext {
  background-color: initial;
  border: 1px solid #474d84;
  color: white !important;
  height: 25px;
  width: 25px;
  margin: 0px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
  font-family: 'Poppins' !important;
}

.pageLink {
  background-color: initial;
  color: white !important;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
  font-family: 'Poppins' !important;
}

.activeLink {
  background-color: #474e80 !important;
  color: white !important;
  text-decoration: none !important;
  font-family: 'Poppins' !important;
}

.totalCount {
  display: flex;
  align-items: center;
  height: 100%;
}

.linkNext {
  border: 1px solid #474d84;
  color: white !important;
  text-decoration: none !important;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins' !important;
}

.linkClass {
  color: white !important;
  text-decoration: none !important;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins' !important;
}

.dropdownClass {
  height: 25px;
  border: 1px solid #474d84;
  padding-top: 1px;
  color: white !important;
  margin: 0px 3px;
  display: flex;
  align-items: center !important;
}

.pageSizeDropdown {
  height: 25px;
  width: 60px;
  border: 1px solid #474d84;
  display: flex;
  align-items: center;
}
.companyName {
  margin-bottom: 0;
  width: 100%;
  display: flex;
  max-width: 95px;
  overflow-x: scroll;
}
.temporarySelectedContainer {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  max-height: 150px;
  overflow-y: scroll;
  word-break: break-word;
}
.temporarySelected {
  display: flex;
  max-width: 300px;
  font-size: 12.25px;
}
.longNameTooltip {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 250px;
  margin-bottom: 0px;
}

.slackNav {
  width: 177px;
}

.dateRangeContainer {
  margin-top: 7px;
}
