/**            UTILS              **/

// Util Components
// ----------------------------

.transition-height {
  @include transition(height 0.15s ease-in-out);
}

$opacity-variants: (
  10: 0.1,
  20: 0.2,
  30: 0.3,
  40: 0.4,
  50: 0.5,
  60: 0.6,
  70: 0.7,
  80: 0.8,
  90: 0.9
);

@include property-variants('.opacity', 'opacity', $opacity-variants);

$width-variants: (
  50: 50px,
  100: 100px,
  150: 150px,
  200: 200px
);

@include property-variants('.width', 'width', $width-variants);

$height-variants: (
  50: 50px,
  100: 100px,
  150: 150px,
  200: 200px,
  250: 250px
);

@include property-variants('.height', 'height', $height-variants);
@include property-variants('.max-height', 'max-height', $height-variants);

$z-index-variants: (
  more: 1,
  less: -1,
  '10': 10,
  '-10': -10
);

@include property-variants('.z-index', 'z-index', $z-index-variants);

.fw-semi-bold {
  font-weight: 600;
}

.fs-sm {
  font-size: $font-size-sm;
}

.text-default {
  color: $text-color;
}

/**
 * .circle & .rounded are pretty cool way to add a bit of beauty to raw data.
 * should be used with bg-* and text-* classes for colors.
 */
.circle,
.rounded,
.circle-o {
  display: inline-block;
  width: #{$line-height-base}em;
  height: #{$line-height-base}em;
  line-height: #{$line-height-base}em;
  text-align: center;
}

.circle,
.circle-o {
  @include border-radius(50%);
}

.rounded {
  @include border-radius(5px);
}

.circle-lg,
.rounded-lg {
  width: 2.2em;
  height: 2.2em;
  line-height: 2.2em;
}

.circle-o {
  border: #{$line-height-base * 0.2}em solid transparent;

  // stylelint-disable
  &.circle-o-danger {
    border-color: $danger;
  }
  &.circle-o-warning {
    border-color: $warning;
  }
  &.circle-o-success {
    border-color: $success;
  }
  &.circle-o-info {
    border-color: $info;
  }
  &.circle-o-primary {
    border-color: $primary;
  }
  &.circle-o-black {
    border-color: $black;
  }
  &.circle-o-gray-darker {
    border-color: $gray-900;
  }
  &.circle-o-gray-dark {
    border-color: $gray-800;
  }
  &.circle-o-gray {
    border-color: $gray-700;
  }
  &.circle-o-gray-light {
    border-color: $gray-600;
  }
  &.circle-o-gray-lighter {
    border-color: $gray-200;
  }
  &.circle-o-white {
    border-color: $white;
  }
  // stylelint-enable
}

@include thumb('.thumb-xl', 100px);
@include thumb('.thumb-lg', 60px);
@include thumb('.thumb', 48px);
@include thumb('.thumb-sm', 34px);
@include thumb('.thumb-xs', 28px);

.thumb-xs,
.thumb-sm,
.thumb-lg,
.thumb-xl,
.thumb {
  position: relative;
  display: inline-block;
  text-align: center;

  img {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
  }

  .status {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 0.7em;
    height: 0.7em;
    border: 0.15em solid $white;

    @include border-radius(50%);

    &.status-bottom {
      top: auto;
      left: auto;
      bottom: 0;
      right: 0;
    }
  }
}

.input-group-dark {
  .input-group-addon,
  .form-control {
    background-color: rgba(0, 0, 0, 0.4);
    border: 0;
    color: $gray-700;

    @include transition(background-color 0.15s ease-in-out);
  }

  .form-control:focus,
  .form-control:focus + .input-group-addon,
  &.focus .input-group-addon {
    background-color: $gray-200;
  }
}

// @include bg-variant('.bg-dark', $text-color, true);
// @include bg-variant('.bg-widget', $widget-bg, true);
.bg-dark {
  @include background-variant($text-color);
}
.bg-widget {
  @include background-variant($widget-bg);
}

.td-underline {
  text-decoration: underline;
}

.display-flex {
  display: flex;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.fw-semi-bold {
  font-weight: $font-weight-semi-bold;
}

.fw-normal {
  font-weight: $font-weight-normal;
}

.fw-bold {
  font-weight: $font-weight-bold;
}

.fw-thin {
  font-weight: $font-weight-thin;
}

.rotate-45 {
  transform: rotate(45deg);
}

.rotate-135 {
  transform: rotate(135deg);
}

.rotate-225 {
  transform: rotate(225deg);
}

.rotate-315 {
  transform: rotate(315deg);
}

// Text & Background Variations
// ----------------------------

//copy bg classes after bootstrap so it's possible to overload other bootstrap classes

.bg-primary {
  @include background-variant($primary);
}
.bg-success {
  @include background-variant($success);
}

.bg-info {
  @include background-variant($info);
}

.bg-warning {
  @include background-variant($warning);
}

.bg-danger {
  @include background-variant($danger);
}

.bg-body {
  @include background-variant($body-bg);
}

.bg-body-light {
  @include background-variant($body-bg-light);
}

.bg-gray {
  @include background-variant($gray-700);
}

.bg-gray-transparent {
  @include background-variant(rgba($gray-700, 0.6));
}

.bg-gray-dark {
  @include background-variant($gray-800);
}

.bg-black {
  @include background-variant($black);
}

.bg-gray-light {
  @include background-variant($gray-600);
}

.bg-gray-lighter {
  @include background-variant($gray-200);
}

.bg-transparent {
  @include background-variant(rgba(0, 0, 0, 0));
}
.bg-white {
  @include background-variant($white);
}
.bg-sidebar {
  @include background-variant($sidebar-bg-color);
}

.bg-body,
.bg-body-light,
.bg-gray,
.bg-gray-transparent,
.bg-transparent,
.bg-primary,
.bg-success,
.bg-warning,
.bg-danger,
.bg-info {
  @include transition(background-color 0.15s ease-in-out);
}

//override bs .text-* classes so they use $brand-* variables

.text-primary {
  @include text-emphasis($primary);
}
.text-success {
  @include text-emphasis($success);
}
.text-info {
  @include text-emphasis($info);
}
.text-warning {
  @include text-emphasis($warning);
}
.text-danger {
  @include text-emphasis($danger);
}
.text-link {
  @include text-emphasis($link-color);
}
.text-white {
  @include text-emphasis(rgba($white, 0.9));
}
.text-gray {
  @include text-emphasis($gray-700);
}
.text-gray-dark {
  @include text-emphasis($gray-800);
}
.text-gray-light {
  @include text-emphasis($gray-600);
}
.text-semi-muted {
  @include text-emphasis($gray-600);
}
.text-gray-darker {
  @include text-emphasis($gray-900);
}
.text-gray-lighter {
  @include text-emphasis($gray-200);
}

.text-gray-darker {
  @include text-emphasis($gray-900);
}

.text-bg-sidebar {
  @include text-emphasis($sidebar-bg-color);
}

.text-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.deemphasize {
  font-size: $font-size-mini;
  color: $text-muted;
}

.help {
  font-size: $font-size-mini;
  color: $text-muted;
}

.text-light {
  color: inherit !important;
  opacity: 0.8;
}

.fs-sm {
  font-size: $font-size-sm;
}

.fs-mini {
  font-size: $font-size-mini;
}

.fs-larger {
  font-size: $font-size-larger;
}

.btlr {
  border-top-left-radius: $border-radius;
}

.btrr {
  border-top-right-radius: $border-radius;
}

.bblr {
  border-bottom-left-radius: $border-radius;
}

.bbrr {
  border-bottom-right-radius: $border-radius;
}

.hide {
  display: none !important;
}

.show {
  min-width: 60px !important;
  display: block !important;
}

.btn-group.show {
  display: inline-flex !important;
}

.help-block {
  margin-top: 5px;
  font-size: $font-size-mini;
  color: $text-muted;
  display: block;
}

@include keyframes('bg-attention') {
  0% {
    background-color: lighten($warning, 43%);
  }

  5% {
    background-color: lighten($warning, 33%);
  }

  0% {
    background-color: rgba(0, 0, 0, 0);
  }
}

.bg-attention {
  animation: bg-attention 10s;
}

.thin-scroll {
  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar:hover {
    background-color: #e9e9e9;
    border: none;
  }

  &::-webkit-scrollbar-thumb {
    border: none;
    background-color: rgba(0, 0, 0, 0.2);

    @include box-shadow(inset 1px 1px 0 rgba(0, 0, 0, 0.1));
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $gray-600;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.input-dark {
  background-color: rgba(0, 0, 0, 0.4);
  border: 0;
  color: $gray-700;

  &:focus {
    background-color: $gray-200;
  }
}

.input-group-dark {
  .input-group-addon,
  .form-control {
    background-color: rgba(0, 0, 0, 0.4);
    border: 0;
    color: $gray-700;

    @include transition(background-color 0.15s ease-in-out);
  }

  .form-control:focus,
  .form-control:focus + .input-group-addon,
  &.focus .input-group-addon {
    background-color: $gray-200;
  }
}

.input-group-transparent {
  .form-control:first-child,
  .input-group-addon:first-child,
  .input-group-btn:first-child > .btn,
  .input-group-btn:first-child > .btn-group > .btn,
  .input-group-btn:first-child > .dropdown-toggle,
  .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  .input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }

  .form-control:last-child,
  .input-group-addon:last-child,
  .input-group-btn:last-child > .btn,
  .input-group-btn:last-child > .btn-group > .btn,
  .input-group-btn:last-child > .dropdown-toggle,
  .input-group-btn:first-child > .btn:not(:first-child),
  .input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    padding-left: 0;
  }

  .input-group-addon {
    background-color: $input-bg;

    @include transition(border-color ease-in-out 0.15s);
  }

  .form-control:focus + .input-group-addon,
  &.focus .input-group-addon {
    border-color: $input-focus-border-color;
  }
}

.input-no-border {
  border: none;

  @include transition(background-color ease-in-out 0.15s);

  &:focus {
    background-color: $gray-100;
  }
}

.input-group-no-border {
  @extend .input-group-transparent;

  .form-control,
  .input-group-addon {
    @extend .input-no-border;
  }

  .form-control:focus,
  .form-control:focus + .input-group-addon,
  &.focus .input-group-addon {
    @extend .input-no-border, :focus;
  }
}

.input-group-rounded {
  .form-control:first-child,
  .input-group-addon:first-child,
  .input-group-btn:first-child > .btn,
  .input-group-btn:first-child > .btn-group > .btn,
  .input-group-btn:first-child > .dropdown-toggle,
  .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  .input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  .form-control:last-child,
  .input-group-addon:last-child,
  .input-group-btn:last-child > .btn,
  .input-group-btn:last-child > .btn-group > .btn,
  .input-group-btn:last-child > .dropdown-toggle,
  .input-group-btn:first-child > .btn:not(:first-child),
  .input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

// Style Classes
// ----------------------------

$margin-vertical-variants: (
  xlg: 40px,
  lg: $spacer * 3 * 0.5,
  md: $spacer,
  sm: $spacer * 0.5,
  xs: $spacer * 0.25,
  0: 0
);
$margin-horizontal-variants: (
  lg: 20px,
  md: 15px,
  sm: 10px,
  xs: 5px,
  0: 0
);

@include property-variants('.mt', 'margin-top', $margin-vertical-variants);
@include property-variants('.mb', 'margin-bottom', $margin-vertical-variants);
@include property-variants('.ms', 'margin-left', $margin-horizontal-variants);
@include property-variants('.me', 'margin-right', $margin-horizontal-variants);

$padding-vertical-variants: (
  xlg: 40px,
  lg: $spacer * 3 * 0.5,
  md: $spacer,
  sm: $spacer * 0.5,
  xs: $spacer * 0.25,
  0: 0
);
$padding-horizontal-variants: (
  lg: 20px,
  md: 15px,
  sm: 10px,
  xs: 5px,
  0: 0
);

@include property-variants('.pt', 'padding-top', $padding-vertical-variants);
@include property-variants('.pb', 'padding-bottom', $padding-vertical-variants);
@include property-variants('.ps', 'padding-left', $padding-horizontal-variants);
@include property-variants('.pe', 'padding-right', $padding-horizontal-variants);

.mt-negative-lg {
  margin-top: -($spacer * 1.5);
}

// Display classes UP

//
// Utilities for common `display` values
//

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-down($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .d#{$infix}-down-none {
      display: none !important;
    }
    .d#{$infix}-down-inline {
      display: inline !important;
    }
    .d#{$infix}-down-inline-block {
      display: inline-block !important;
    }
    .d#{$infix}-down-block {
      display: block !important;
    }
    .d#{$infix}-down-table {
      display: table !important;
    }
    .d#{$infix}-down-table-cell {
      display: table-cell !important;
    }
    .d#{$infix}-down-flex {
      display: flex !important;
    }
    .d#{$infix}-down-inline-flex {
      display: inline-flex !important;
    }
  }
}

/* .h* + .fw-bold */
$font-sizes: 54px, $h1-font-size, $h2-font-size, $h3-font-size, $h4-font-size, $h5-font-size,
  $h6-font-size;
$i: 0;

@each $font-size in $font-sizes {
  .value#{$i} {
    margin: 0;
    font-size: $font-size;
    font-weight: $font-weight-semi-bold;
  }
  $i: $i + 1 !global;
}

.chart-overflow-bottom {
  margin: 0 (-$widget-padding-horizontal) (-$widget-padding-vertical);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}

// Background

.background-cover {
  background-size: cover;
  background-position: center;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
  -webkit-transform: translate3d(0, 4%, 0) scale(0.93);
  transform: translate3d(0, 4%, 0) scale(0.93);
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in, -webkit-transform 200ms ease-in, transform 200ms ease-in;
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1);
}

.btn-custom-dark {
  background: $bg-custom-dark !important;
  color: $content-color;
}

.bg-custom-dark {
  background: $bg-custom-dark !important;
}

.bg-subtle-blue {
  background: $subtle-blue !important;
}

.btn-subtle-blue {
  background: $subtle-blue !important;
  color: $content-color;
}

.flex-wrap {
  flex-wrap: wrap;
}
