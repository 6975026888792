@import "../../../styles/app";$prefix: '.re-cron';

#{$prefix}-row {
  overflow: hidden;
  #{$prefix}-col-1 {
    width: calc(100% / 12);
    float: left;
  }
  #{$prefix}-col-2 {
    width: calc(100% / 6);
    float: left;
  }
}
#{$prefix}-form-inline {
  display: flex;
  flex-direction: row;
}
#{$prefix}-form-control {
  margin: 0 .2rem;
}
#{$prefix}-form-check-label {
  padding-left: .4rem;
}


/* React JS Cron */
/* Custom style */
.my-project-cron-field > span {
  font-weight: bold !important;
  margin: 20px !important;
}
.my-project-cron-error-field > span {
  color: #531e1e !important;
}
.my-project-cron .my-project-cron-select > div:first-child {
  border-radius: 10px !important;
}
.my-project-cron-select-dropdown
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #dadada !important;
}
.my-project-cron-clear-button {
  border-radius: 10px !important;
}
