.p-treenode-content {
  display: flex;
  align-items: center;
}

.p-treenode-content > button {
  width: 14px;
  height: 35px;
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-bottom: 7px;
  color: white;
}

.p-treenode-content > div {
  width: 100%;
  height: 35px;
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
}

.p-treenode .p-treenode-leaf {
  font-weight: 100;
}

.tree-icon {
  font-weight: bold;
  width: 20px !important;
  height: 20px !important;
  margin-bottom: 8px;
  cursor: pointer;
}

.tree-container {
  display: flex;
  overflow-y: scroll !important;
  height: 90vh !important;
  width: 100%;
}

.files-list {
  min-height: 70vh;
  max-height: 85vh !important;
  margin-top: 20px;
  overflow-y: scroll;
}

.total-number {
  display: flex;
  align-items: center;
  height: 35px;
}

.file-preview {
  display: flex;
  overflow-y: scroll;
  justify-content: center;
}

.p-treenode {
  cursor: pointer !important;
  padding: 5px 5px;
  font-size: 15px;
}

.folder-icon {
  width: 20px;
  height: 20px;
}

.file-item {
  cursor: pointer;
  padding: 5px 5px;
  font-size: 16px;
}

.item-preview {
  width: 100%;
  height: 100%;
  overflow-y: none !important;

  @media screen and (max-width: 1920px) {
    padding-left: 15%;
    padding-right: 15%;
    overflow-y: none !important;
    width: 100%;
    height: 100%;
  }
}
