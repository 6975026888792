.icon {
  color: white;
  width: 15px;
  height: 15px;
  margin-right: 10px;
}
.certificateName {
  word-break: break-word;
  max-height: 90px;
  display: flex;
  overflow-y: scroll;
  flex-wrap: wrap;
}
.errorMessage {
  color: #db2a34;
  font-size: 11.2px;
  margin-bottom: 5px;
}
