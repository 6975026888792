@import '../../../../styles/app.scss';
.activeWidget {
  border: 3px solid $primary !important;
  background-color: transparentize($primary, 0.8);
}

.nodeSelect {
  background-color: #4c6ef5;
  font-weight: bold;
  padding: 5px 5px;
}

.nodeHover {
  &:hover {
    background-color: #627dff;
  }
}
