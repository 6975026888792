/**
 * Customized version of bootstrap using variables from _variables.scss.
 * This file is loaded via separate loader thus allowing to use original bootstrap classes (e.g. .btn-default) through out the app.
 */
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,600,700,800');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');
@import '../../node_modules/bootstrap/scss/functions';
@import 'variables';
@import 'theme-variables';
@import '../../node_modules/bootstrap/scss/bootstrap';
@import '../../node_modules/line-awesome/dist/line-awesome/css/line-awesome.css';
@import '../fonts/flaticon/flaticon';
@import '../../node_modules/animate.css/animate';
@import '../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
@import '../../node_modules/react-toastify/dist/ReactToastify.css';

@import 'mixins';
@import 'base';
@import 'auth';
@import 'overrides';
@import 'general';
@import 'utils';
