html {
  font-weight: 100;
  font-size: $font-size-initial;
}

body {
  overflow-x: hidden;
  color: $text-color;
  background: var(--background-color);
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: 'Poppins' !important;
}

html,
body,
#app {
  height: 100%;
}

a,
.dropdown-item,
.btn {
  cursor: pointer;
  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out,
    border-color 0.1s ease-in-out;
  font-family: $font-family-base;
  border-radius: 2px !important;
}

.search-button-with-icon {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.search-input {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  color: $text-color !important;
}
.search-input::placeholder {
  color: #f4f4f580;
  opacity: 1;
}

.case-info-text {
  font-size: 15px;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

th {
  font-family: 'Poppins' !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

td {
  font-family: 'Poppins' !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

label {
  font-weight: 400;
}

strong {
  font-weight: 700;
}

ul ul,
ol ul {
  list-style: none;
}

ul,
ol {
  list-style: none;
  padding-left: 0;
}

body,
* {
  /* For Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent !important;
  }
  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px #3978f6 !important;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-corner {
    display: none;
  }
}

.table-container {
  min-height: 280px;
  max-height: 500px;
  width: 100% !important;
  overflow-y: scroll;
  overflow-x: auto;
}
.custom-table {
  width: 100% !important;
  height: 100% !important;
  tr {
    width: 100%;
  }
  tr,
  td {
    padding: 10.5px;
    text-align: left;
    width: 100px;
  }
  th,
  td {
    padding: 10.5px;
    text-align: left;
    width: 100px;
  }
  .dropdown-menu > button > p {
    display: flex !important;
    white-space: nowrap;
    flex-wrap: nowrap;
    padding: 0;
  }
}

.firefox-layout-container {
  /* For Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #3978f6 transparent;
  }
}

.first-headline-text {
  font-family: 'Poppins' !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.second-headline-text {
  font-family: 'Poppins' !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.first-subtitle-text {
  font-family: 'Poppins' !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.second-subtitle-text {
  font-family: 'Poppins' !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.first-body-text {
  font-family: 'Poppins' !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.second-body-text {
  font-family: 'Poppins' !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.first-caption-text {
  font-family: 'Poppins' !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.second-caption-text {
  font-family: 'Poppins' !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
}

.dropdown-position-fixed {
  position: fixed;
  min-width: 60px;
}
