.credentialName {
  word-break: break-word;
  max-height: 90px;
  display: flex;
  overflow-y: scroll;
  flex-wrap: wrap;
}
.exportButton {
  background-color: #474d84 !important;
  font-weight: bold;
  color: white !important;
  font-size: 14px;
  height: 37px;
  border: 1px solid #474d84;
  width: 90px;
  &:hover {
    background-color: #474d84 !important;
    font-weight: bold;
    color: white !important;
    font-size: 14px;
    border: 1px solid #474d84;
  }
  &:active {
    background-color: #474d84 !important;
    font-weight: bold;
    color: white !important;
    font-size: 14px;
    border: 1px solid #474d84;
  }
}
.infoIcon {
  cursor: pointer !important;
}
.popupTable {
  width: 90%;
  margin-left: 5%;
}
.popupTable tbody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 120px;
  overflow-y: auto;
  text-align: center;
}
.popupTable tr {
  display: flex;
}
.popupTable tr td:nth-child(1) {
  width: 20%;
}
.popupTable tr th:nth-child(1) {
  width: 20%;
  text-align: center;
}
.popupTable tr td:nth-child(2) {
  width: 80%;
}
.popupTable tr th:nth-child(2) {
  width: 80%;
  text-align: center;
}
.popupTableContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tooltipHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-bottom: 0px;
}
.errorMessage {
  color: #db2a34;
  font-size: 11.2px;
  margin-bottom: 5px;
}

.hiddenFileInput {
  display: none;
}

.uploadFile {
  cursor: pointer;
  margin-right: 8px;
}
