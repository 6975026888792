.statsWidget {
  height: 100px;
}

.container {
  position: relative;
}

.progressBarHeader {
  font-weight: bold;
}

.progressBar {
  position: relative;
  left: 95px;
  top: -30px;
  width: 100px;
}

.exportName {
  overflow-x: scroll;
  width: 100%;
}

.widgetHeader {
  margin-bottom: 20px;
}

.policyDetails {
  margin-left: 6%;
}

@media screen and (max-width: 1920px) {
  .policyDetails {
    margin-left: 11.5%;
  }
}
