@import '../../../../styles/app.scss';
.activeWidget {
  border: 3px solid $primary !important;
  background-color: transparentize($primary, 0.8);
}

.widgetList {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  height: 100vh;
  overflow: auto;
  overflow-x: hidden;
}

.autoSize {
  &::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent !important;
  }
  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px #3978f6 !important;
    border-radius: 6px;
  }
}

.emailWidget {
  &::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent !important;
  }
  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px #3978f6 !important;
    border-radius: 6px;
  }
}

.casesList {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-height: 100vh;
}

.pagination {
  bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.widgetItemsList {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-height: 100vh;
  background: var(--widget-bg-color);
}

.searchItemsList {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
}

.caseStatsPage {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.statsContainer {
  max-height: 100vh;
}

.mainContainer {
  display: flex;
  justify-content: space-around;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
}

.itemsList {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  min-width: 60%;
}

.popupTable {
  width: 70%;
  margin-left: 15%;
}

.popupTable tbody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 120px;
  overflow-y: auto;
  text-align: center;
}

.popupTable tr {
  display: flex;
  justify-content: space-between;
}

.popupTable tr td {
  width: 100%;
}

.popupTable tr th {
  width: 100%;
  text-align: center;
}

.popupTableContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupHeader {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.caseUsersContainer {
  overflow-y: scroll;
  height: 110px;
}
