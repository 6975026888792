#root {
  --sidebar-item-active: #f6f6e9;
  --important-notification-color: #f55d5d;
  --badge-item: #ffffff;
  --update-notification-color: #fd5f00;

  .theme-333964 {
    --background: radial-gradient(farthest-side ellipse at 10% 0, #333867 20%, #17193b);
    --widget-bg: rgba(0, 0, 0, 0.24);
    --widget-shadow: rgba(4, 7, 30, 0.4);
    --sidebar-list-color: rgba(18, 20, 43, 0.42);
    --modal-bg: #1b1e3c;
    --text-color: rgba(255, 255, 255, 0.9);
    --input-field-bg: #010002;
    --button-bg: #3978f6;
    --expand-row-bg: #474d84;
    --button-secondary-bg: #474d84;
  }

  .theme-0A0417 {
    --background: radial-gradient(farthest-side ellipse at 10% 0, #0a0417 20%, #0d1635);
    --widget-bg: linear-gradient(135deg, #2b253c 0%, rgba(43, 37, 60, 0.64) 100%);
    --widget-shadow: rgba(4, 7, 30, 0.4);
    --sidebar-list-color: #2b253c;
    --modal-bg: #04010a;
    --text-color: rgba(255, 255, 255, 0.9);
    --input-field-bg: #010002;
    --button-bg: #19277a;
    --expand-row-bg: #19277a;
    --button-secondary-bg: #5f598a;
  }
  .theme-3846AA {
    --background: radial-gradient(farthest-side ellipse at 10% 0, #3846aa 20%, #0b0d2c);
    --widget-bg: linear-gradient(135deg, rgba(101, 114, 207, 0.63) 0%, rgba(32, 38, 82, 0) 100%);
    --widget-shadow: rgba(4, 7, 30, 0.4);
    --sidebar-list-color: #25307e;
    --modal-bg: #1c2b99;
    --text-color: rgba(255, 255, 255, 0.9);
    --input-field-bg: #121e77;
    --button-bg: #6370be;
    --expand-row-bg: #2e3673;
    --button-secondary-bg: #8b8db9;
  }

  .theme-3390C3 {
    --background: linear-gradient(136.36deg, #3390c4 4.86%, #1d1252 94.85%);
    --widget-bg: linear-gradient(
      172.98deg,
      rgba(255, 255, 255, 0.08) 1.49%,
      rgba(255, 255, 255, 0.0208) 99.64%
    );
    --widget-shadow: rgba(4, 7, 30, 0.4);
    --sidebar-list-color: rgba(41, 85, 142, 0.5);
    --modal-bg: #127db6;
    --text-color: rgba(255, 255, 255, 0.9);
    --input-field-bg: #065d8c;
    --button-bg: #065d8c;
    --expand-row-bg: #065d8c;
    --button-secondary-bg: #528fc2;
  }

  // dependable variables
  .light-blue-dashboard {
    --widget-bg-color: var(--widget-bg);
    --background-color: var(--background);
    --sidebar-color: var(--sidebar-list-color);
    --badge-item-color: var(--badge-item);
    --important-notification: var(--important-notification-color);
    --update-notification: var(--update-notification-color);
    --sidebar-item-active-color: var(--sidebar-item-active);
    --chat-sidebar-bg-color: var(--sidebar-bg-color);
    --sidebar-progress-bg-color: var(--sidebar-action-bg);
    --sidebar-item-hover-bg-color: var(--sidebar-action-bg);
    --widget-shadow-color: var(--widget-shadow);
    --main-text-color: var(--text-color);
    --modal-bg-color: var(--modal-bg);
    --input-field-bg-color: var(--input-field-bg);
    --primary-button-bg: var(--button-bg);
    --expand-row-bg-color: var(--expand-row-bg);
    --secondary-button-bg: var(--button-secondary-bg);
  }
}
