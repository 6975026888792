@import '../../styles/app';

.errorPage {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorBtn {
  padding-left: 35px;
  padding-right: 35px;
  width: 200px;
  height: 60px;
  font-size: 16px;
  @media screen and (max-width: 1920px) {
    width: 150px;
    height: 40px;
    font-size: 14px;
  }
}

.errorHeadline {
  font-family: 'Poppins' !important;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  font-weight: 600;
  text-align: center;

  @media screen and (max-width: 1920px) {
    font-size: 30px;
  }
}

.errorCode {
  font-size: 300px;
  font-weight: $font-weight-normal;
  color: $blue;
  text-align: center;
  margin-bottom: 0px;

  @media screen and (max-width: 1920px) {
    font-size: 170px;
  }
}

.errorInfo {
  font-size: 18px;
  color: #f4f4f5e6;
  padding: 20px 0px;
  text-align: center;
  width: 600px;
  @media screen and (max-width: 1920px) {
    font-size: 15px;
    width: 400px;
  }
}
