@import './app.scss';
button.btn.bg-transparent {
  background-color: var(--primary-button-bg);
  border: var(--primary-button-bg) !important;
  color: $white;
  &:hover {
    text-decoration: none;
    color: $white;
    background: transparent !important;
  }
  &:active {
    text-decoration: none;
    color: $white;
    background-color: transparent !important;
  }
  &:focus {
    color: $white;
    background-color: transparent !important;
  }
  &:disabled {
    cursor: default !important;
  }
}

.btn {
  background-color: var(--primary-button-bg);
  border: var(--primary-button-bg) !important;
  color: $white;
  &:hover {
    text-decoration: none;
    color: $white;
    background-color: var(--primary-button-bg) !important;
  }
  &:active {
    text-decoration: none;
    color: $white;
    background-color: var(--primary-button-bg) !important;
  }
  &:focus {
    color: $white;
    background-color: var(--primary-button-bg) !important;
  }
  &:disabled {
    background-color: var(--primary-button-bg);
    cursor: default !important;
  }
}

.secondary-btn {
  background-color: var(--secondary-button-bg);
  border: var(--secondary-button-bg) !important;
  color: $white;
  &:hover {
    text-decoration: none;
    color: $white;
    background-color: var(--secondary-button-bg) !important;
  }
  &:active {
    text-decoration: none;
    color: $white;
    background-color: var(--secondary-button-bg) !important;
  }
  &:focus {
    color: $white;
    background-color: var(--secondary-button-bg) !important;
  }
  &:disabled {
    background-color: var(--secondary-button-bg);
    cursor: default !important;
  }
}

.danger-btn {
  background-color: #db2a34;
  border: #db2a34 !important;
  color: $white;
  height: 35px;
  &:hover {
    text-decoration: none;
    color: $white;
    background-color: #db2a34 !important;
  }
  &:active {
    text-decoration: none;
    color: $white;
    background-color: #db2a34 !important;
  }
  &:focus {
    color: $white;
    background-color: #db2a34 !important;
  }
  &:disabled {
    background-color: #db2a34;
    cursor: default !important;
  }
}
