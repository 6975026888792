@import '../../../styles/app';
.widgetItemsList {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
}

.auditItemsList {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
  margin-bottom: 5px;
}

.pagination {
  bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.icon {
  width: 5px;
  height: 5px;
  margin-right: 10px;
}

.auditType {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.container {
  min-height: 100vh;
  display: flex;
}

.widget {
  min-height: 100%;
}

.filterContainer {
  min-height: 100vh;
}

.quickResultContainer {
  min-height: 100vh;
  max-height: 120vh;
  display: flex;
  flex-direction: column;
  align-items: space-between;
}

.activeWidget {
  border: 3px solid $primary !important;
  background-color: transparentize($primary, 0.8);
}

.settingsIcon {
  color: white;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.details {
  margin-right: 5px;
  font-weight: bold;
}

.bodyText {
  margin-right: 5px;
}

.detailsView {
  display: flex;
  flex-direction: column;
  height:100%;
}

.additionalInfo {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  margin-top: 20px;
}

.errorMessage {
  color: #db2a34;
  font-size: 11.2px;
  margin-bottom: 5px;
}

.resetButton {
  width: 70px;
  height: 25px;
  margin-top: 32px;
  margin-left: 5px;
}

.auditTableHeader {
  margin-top: 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.noRecordsText {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.activityCaseName {
  word-break: break-word;
  max-height: 90px;
  display: flex;
  overflow-y: scroll;
  flex-wrap: wrap;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.toggleButton {
  height: 37px;
  margin-right: 30px;
}

.expandedRowContainer {
  width: 100%;
  min-height: 40px;
  max-height: 150px;
  display: flex;
  margin-bottom: 10px;
  margin-top: 0px;
  overflow-y: scroll;
}

@media (min-width: 2560px) {
  .expandedRowContainer {
    width: 100%;
    min-height: 40px;
    max-height: 150px;
    display: flex;
    margin-bottom: 10px;
    margin-top: 0px;
    overflow-y: scroll;
  }
}

.expandedRowHeader {
  padding-top: 10px;
  margin-left: 10px;
  font-weight: bold;
  font-size: 12px;
}

.parentRow {
  width: 100%;
  background-color: var(--expand-row-bg-color) !important;
}

.detailsExpanded {
  font-weight: bold;
  margin-right: 5px;
  margin-bottom: 0;
}

.additionalInfoExpandedHeader {
  font-weight: bold;
  margin-right: 10px;
}

.contentContainer {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-top: 10px;
}

.recordDetails {
  margin-right: 10px;
}

.auditRecords {
  display: flex;
  height: 40px;
  overflow-y: scroll;
  margin-left: 5px;
  padding-top: 3px;
}

.detailedContainer{
  min-height: 100%;
}