@import '../../../../styles/app.scss';
.paginationNext {
  background-color: initial;
  border: 1px solid #474d84;
  color: white !important;
  height: 33px;
  width: 33px;
  margin: 0px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
  font-family: 'Poppins' !important;
}

.pageLink {
  background-color: initial;
  color: white !important;
  height: 33px;
  width: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
  font-family: 'Poppins' !important;
}

.activeLinkClass {
  background-color: #474e80 !important;
  color: white !important;
  text-decoration: none !important;
  font-family: 'Poppins' !important;
}

.caseActiveLinkClass {
  background-color: #474e80 !important;
  color: white !important;
  text-decoration: none !important;
  height: 33px;
  width: 33px;
}

@media screen and (max-width: 1920px) {
  .caseActiveLinkClass {
    background-color: #474e80 !important;
    color: white !important;
    text-decoration: none !important;
    height: 25px;
    width: 25px;
  }
}

.totalCount {
  margin-bottom: 0px;
  margin-top: 7px;
}

.linkNext {
  background-color: initial;
  border: 1px solid #474d84;
  color: white !important;
  text-decoration: none !important;
  height: 33px;
  width: 33px;
  margin: 0px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1920px) {
  .linkNext {
    border: 1px solid #474d84;
    color: white !important;
    text-decoration: none !important;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.linkClass {
  background-color: initial;
  color: white !important;
  text-decoration: none !important;
  height: 33px;
  width: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins' !important;
}

@media screen and (max-width: 1920px) {
  .linkClass {
    color: white !important;
    text-decoration: none !important;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins' !important;
  }
}

.dropdownClass {
  height: 33px;
  border: 1px solid #474d84;
  color: white !important;
}

@media screen and (max-width: 1920px) {
  .dropdownClass {
    height: 25px;
    border: 1px solid #474d84;
    padding-top: 1px;
    color: white !important;
    margin: 0px 3px;
    display: flex;
    align-items: center !important;
    font-family: 'Poppins' !important;
  }
}

.dropdownItem {
  font-family: 'Poppins' !important;
  &:hover {
    background-color: var(--primary-button-bg) !important;
  }
}

.pageSizeDropdown {
  height: 33px;
  width: 60px;
  border: 1px solid #474d84;
}

ul {
  li {
    padding: 0 3px 0 3px;
  }
}

.holdListName {
  word-break: break-word;
  max-height: 90px;
  display: flex;
  overflow-y: scroll;
  flex-wrap: wrap;
}

.holdListDescription {
  word-break: break-word;
  max-height: 90px;
  display: flex;
  overflow-y: scroll;
  flex-wrap: wrap;
}

.deleteModalContainer {
  text-align: center;
}

.deleteModalFooter {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.deleteButton {
  margin-right: 20px;
}

.exportButton {
  background-color: #474d84 !important;
  font-weight: 500;
  color: white !important;
  font-size: 14px;
  height: 37px;
  font-family: 'Poppins' !important;
  &:hover {
    background-color: #474d84 !important;
    font-weight: 500;
    color: white !important;
    font-size: 14px;
    font-family: 'Poppins' !important;
  }
  &:active {
    background-color: #474d84 !important;
    font-weight: 500;
    color: white !important;
    font-size: 14px;
    font-family: 'Poppins' !important;
  }
}

.exportItem {
  background-color: #000000cc !important;
  padding-bottom: 5px;
  width: 92px;
  &:hover {
    background-color: #474d84 !important;
    color: white !important;
    font-size: 14px;
    width: 92px;
  }
}

.warningMessage {
  margin-bottom: 15px;
  font-size: 15px;
}

.warningModalHeader {
  height: 75px;
}

.actionsButton {
  background-color: var(--primary-button-bg) !important;
  color: white !important;
  height: 37px;
  &:hover {
    background-color: var(--primary-button-bg) !important;
    color: white !important;
  }
  &:active {
    background-color: var(--primary-button-bg) !important;
    color: white !important;
  }
}

.messageClass {
  width: 100%;
  overflow-x: scroll;
}

.cardName {
  max-width: 90%;
  overflow-x: scroll;
  white-space: nowrap;
}

.sectionHeader {
  margin-bottom: 2px;
}

.mailIcon {
  margin-bottom: 2px;
}

.chatIcon {
  margin-bottom: 3px;
}

.indexButton {
  width: 90px;
  height: 28px;
  margin-bottom: 0px;
  background-color: #474d84 !important;
  &:hover {
    background-color: #474d84 !important;
  }
  &:disabled {
    background-color: #474d84 !important;
  }
  &:active {
    background-color: #474d84 !important;
  }
  &:focus {
    background-color: #474d84 !important;
  }
}

.warningIcon {
  width: 60px;
  height: 60px;
  color: yellow;
}

.modalHeader {
  padding: 20px 30px 15px 30px !important;
}

.confirmBtn {
  width: 80px;
}

.mainHeader {
  font-weight: 500;
  text-align: center;
}

.confirmationBtn {
  display: flex;
  flex-direction: column;
}
