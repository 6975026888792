@import '../../styles/app';

.colorsList {
  display: flex;
  flex-wrap: wrap;
  width: 350px;
  justify-content: space-between;

  .colorBox {
    width: 35px;
    height: 35px;
    border-radius: 0;
    cursor: pointer;
    margin: 3px;
    transition: $transition-base;

    :global {
      .rc-color-picker-trigger {
        width: 100%;
        height: 100%;
      }
    }

    &.active {
      border: 2px solid darken($border-color, 10%);
    }
  }
}
.themeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.themeLabel {
  text-align: center;
}

.colorPickerContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.themeColors {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.themeBtn {
  display: flex;
  justify-content: end;
  margin-right: 30px;
  margin-top: 10px;
}
