.widgetRow {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 10px;
}

.widget {
  min-width: 480px;
  max-width: 80%;
  min-height: auto;
  max-height: 500px;
  margin: 15px;
  flex-shrink: 0;
}

.chatWidget {
  height: 450px;
  min-width: 430px;
  overflow-y: auto;
  padding: 5px 5px !important;
}

.mailWidget {
  height: 500px;
  min-width: 430px;
  overflow-y: auto;
  padding: 5px 5px !important;
}

.resultItem {
  min-width: 115px !important;
  margin-right: 10px;
  margin-bottom: 0px !important;
}

.cardHeaderContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.cardHeader {
  width: 90%;
}

.headerIcon {
  width: 10%;
}

.confirmIcon {
  width: 25px;
  height: 25px;
  font-weight: bold;
}

.subHeaders {
  font-weight: bold;
}

.statsText {
  font-weight: bold;
  font-size: 14px;
}

.filtersText {
  min-width: 125px !important;
  margin-right: 10px;
  margin-bottom: 0px !important;
}

.confirmBtn {
  display: flex;
  flex-direction: column;
}

.channelsList {
  width: 100%;
  overflow-x: scroll;
  font-size: 14px;
  margin-top: 2px;
}
