@import '../../styles/app';

.root {
  :global {
    .post-comments footer {
      margin: 0 (-$widget-padding-horizontal) (-$widget-padding-vertical);
      padding: $widget-padding-vertical $widget-padding-horizontal;
    }

    .stat-item .name {
      margin-top: 10px;
    }
  }
}

.table {
  :global(.table.table-sm) {
    td {
      font-size: 1rem;
      vertical-align: middle;
    }
  }
}
.dashboardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.statsWidget {
  width: 100%;
  margin-bottom: 50px;
}

.section {
  min-width: 250px;
  height: 300px;
  margin: 25px;
}

.largeSection {
  min-width: 250px;
  height: 400px;
  margin: 25px;
}

.exportStatusChart {
  margin-bottom: 0px;
}

.legalCaseStatusChart {
  min-height: 300px;
  margin-bottom: 0px;
}

.widgetBorder {
  border-bottom: 2px solid white;
  padding-bottom: 10px;
  width: 100%;
  margin-bottom: 20px;
}

.stats {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10;
}

.statsWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  margin-right: 5%;
}

.statsLabel {
  font-weight: 600;
  width: 75%;
}

.statsValue {
  width: 30%;
}

.authorizedUserTable {
  width: 100% !important;
}

.authorizedUserTable tbody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 100%;
  max-height: 180px;
  overflow-y: auto;
  overflow-x: auto;
  text-align: start;
}

.authorizedUserTable tr {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.authorizedUserTable tr td:first-child {
  width: 25% !important;
}

.authorizedUserTable tr th:first-child {
  width: 25% !important;
}

.authorizedUserTable tr td:nth-child(2) {
  width: 40% !important;
}

.authorizedUserTable tr th:nth-child(2) {
  width: 40% !important;
}

.authorizedUserTable tr td:nth-child(3) {
  width: 35% !important;
}

.authorizedUserTable tr th:nth-child(3) {
  width: 35% !important;
}

.authorizedUserContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
