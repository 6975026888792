@import './app';
.container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

.navbar {
  border: none;
  font-weight: 500;
  padding: 0.9rem 2rem;

  .nav {
    height: 100%;
  }

  .nav-item {
    .nav-link {
      display: flex;
      align-items: center;
      color: $navbar-link-color;
      height: 100%;
      position: relative;

      &:hover {
        color: $navbar-link-hover-color;
        background: $navbar-link-hover-bg;
      }

      .la {
        font-size: 20px;
      }
    }
  }

  .nav > .nav-item > .nav-link.bs-tether-target.show {
    display: flex !important;
  }

  .dropdown-menu {
    margin: 0;
  }

  @include media-breakpoint-down(sm) {
    font-size: $font-size-lg;
  }
}

.nav {
  .dropdown-toggle {
    color: $white;
  }

  .nav-item.dropdown.show .nav-link.active,
  .nav-item.show .dropdown-toggle,
  .nav-link.active,
  .dropdown-menu {
    color: $white;
    background-color: rgba($widget-bg-color, 1);
  }
}

.progress-bar {
  @include box-shadow(none);
}

.progress {
  height: 15px;
  margin-bottom: $spacer * 0.5;
  background: $subtle-blue;
}

.progress-sm {
  height: 10px;
}

.progress-xs {
  height: 5px;
}
.progress-xs-wizard {
  height: 5px;
  .progress-bar {
    background-color: #2d8515;
  }
}

.progress-md {
  height: 25px;
}

.form-control {
  font-family: $font-family-base;
  box-shadow: none;
  transition: border-color ease-in-out 0.15s, background-color ease-in-out 0.15s;
  background-color: $bg-custom-dark !important;
  border: none;

  &:focus {
    box-shadow: none;
    background: $bg-custom-dark;
  }
}

.css-1fhf3k1-control {
  background-color: #13152b !important;
  border-color: #13152b !important;
}

.form-control:disabled {
  background-color: #13152b !important;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: $spacer;
  line-height: inherit;
  color: $text-muted;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
  font-size: 15px;
  font-weight: $font-weight-normal;
}

.input-sm {
  font-size: 13px;
}

.alert {
  font-size: $font-size-sm;

  .close {
    color: rgba(#000, 0.4);
  }
}

.alert-sm {
  padding: 10px 15px;

  .close {
    font-size: 20px;
    top: 0;
    right: -8px;
  }
}

/* React JS Cron */
/* Custom style */
.my-project-cron-field > span {
  font-weight: 400 !important;
  padding-top: calc(0.5rem + 1px) !important;
  padding-bottom: calc(0.5rem + 1px) !important;
  margin-bottom: 0 !important;
  font-size: inherit !important;
  line-height: 1.5 !important;
}
.my-project-cron-error-field > span {
  color: #531e1e !important;
}

.my-project-cron-select-dropdown {
  background: $bg-custom-dark !important;
}

.react-js-cron-clear-button {
  @extend .btn;
  padding: 0.2rem 0.7rem 0.3rem 0.7rem !important;
  color: #f4f4f5 !important;
  background-color: #3978f6 !important;
  border-color: #3978f6 !important;
}
.react-js-cron-disabled .react-js-cron-select.ant-select-disabled {
  background: none !important;
}

div.react-js-cron-select.react-js-cron-custom-select.ant-select div.ant-select-selector {
  display: flex !important;
  border: none !important;
}

.ant-select-arrow {
  color: $text-color !important;
}

div.react-js-cron-custom-select {
  background: $sidebar-bg-color !important;
}

.ant-select-selector,
.ant-select-selection-overflow-item > span,
.ant-select-selection-overflow {
  background-color: $bg-custom-dark !important;
  border: none !important;
  display: inline-block !important;
  color: $text-color !important;
}

.my-project-cron-select .ant-select-item:not(.ant-select-item-option-disabled) {
  background-color: red !important;
  color: $text-color !important;
}

.my-project-cron-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: $gray-700 !important;
  color: $text-color !important;
}
.my-project-cron-select-dropdown .ant-select-item-option {
  color: $text-color !important;
}

:where(.css-dev-only-do-not-override-ixblex).ant-select .ant-select-clear {
  background: $bg-custom-dark !important;
  color: white !important;
}
.my-project-cron-clear-button {
  border-radius: 10px !important;
}

/*  Badge */
// Add colors for badges
.badge {
  padding: 4px 8px;
  font-weight: $font-weight-semi-bold;
  font-size: 14px;
  line-height: 19px;
}

.badge-warning {
  color: $white;
}

// .badge-white {
//   @include badge-variant($white);
// }

// .badge-default {
//   @include badge-variant($subtle-blue);
// }

.badge {
  &.badge-pill {
    padding: 0.4em 0.6em;
    font-weight: $font-weight-semi-bold;
  }

  .list-group-item > & {
    margin-top: 2px;
  }
}

.table-no-border {
  // margin-left: -$table-cell-padding;
  // margin-right: -$table-cell-padding;

  > thead > tr > th,
  > thead > tr > td,
  > tbody > tr > th,
  > tbody > tr > td,
  > tfoot > tr > th,
  > tfoot > tr > td {
    border-top: 0;
  }
}

.table-sm {
  font-size: $font-size-sm;

  > thead > tr > th,
  > thead > tr > td,
  > tbody > tr > th,
  > tbody > tr > td,
  > tfoot > tr > th,
  > tfoot > tr > td {
    padding: 6px;
  }

  &.table-no-border {
    margin-left: -4px;
    margin-right: -4px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 300;
}

.small,
small {
  font-size: 85%;
}

.text-muted {
  font-size: 85%;
  color: #f4f4f580 !important;
}
///////// Buttons //////////

button.btn.filters {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-toggle::after {
  font-family: Line Awesome Free, sans-serif;
  content: '\F107';
  border: none;
  width: auto;
  height: auto;
  vertical-align: baseline;
  opacity: 0.8;
  font-size: 85%;
  font-weight: 600;
  padding-left: 5px;
  padding-right: 20px;
}

.btn-link {
  &:focus {
    box-shadow: none;
  }
}

.btn-secondary {
  border-color: $gray-400;
  color: $white;
}

.btn-success,
.btn-info,
.btn-warning {
  color: $white;

  &:hover {
    color: $white;
  }
}

.btn-gray {
  border-color: $gray;
  color: $white;
}

.btn-default {
  border-color: $subtle-blue;
  color: $white;
}

.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-secondary.active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn {
  font-family: $font-family-sans-serif;

  .glyphicon {
    top: 3px;
  }
}

.btn,
.btn-inverse,
.btn-gray {
  &:not(.active) {
    box-shadow: none !important;
  }
}

.btn-rounded {
  @include border-radius(6px);
}

.btn-rounded-f {
  @include border-radius(50px);
}

.btn-outline {
  @include button-variant(transparent, $white);

  &:hover,
  &:focus {
    background-color: rgba($white, 0.1);
  }
}

.btn-transparent {
  background: transparentize($text-transparent, 0.2);
  color: $white;
  text-shadow: none;

  @include box-shadow(none);

  &:hover {
    background: $text-transparent;
    color: $white;
  }

  &.active {
    color: $white;
    background: darken($widget-bg-color, 20%);

    &:hover {
      background: darken($widget-bg-color, 20%);
    }
  }

  .caret {
    border-top-color: $white;
  }
}

///////// Dropdowns //////////

.dropdown {
  display: flex !important;
  white-space: nowrap;
  flex-wrap: nowrap;
  &.show {
    .dropdown-menu > button > p {
      display: flex !important;
      white-space: nowrap;
      flex-wrap: nowrap;
    }
  }
}

.nav-item.dropdown {
  &.show {
    .nav-link.active {
      background: $navbar-link-active-bg;
      color: $navbar-link-active-color;
    }
  }
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid;
  // IE8
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

///////// Alerts //////////
.card {
  background-color: $widget-bg-color;
}

///////// Alerts //////////

.alert {
  border: none;

  .btn {
    border: none;
  }

  button.close {
    font-weight: $font-weight-thin;
    font-size: 18px;
    text-shadow: none;
    opacity: 1;
    color: #fff;
    outline: none;
    padding: 0;
    position: static;

    &.alert-transparent {
      color: inherit;
      opacity: 1;
    }
  }
}

.alert-white button.close {
  color: $gray-600;
}

.alert-rounded {
  border-radius: 50px;
}

.alert-success {
  background: $success;
  color: $white;

  &.alert-transparent {
    color: $success;
    background: rgba($success, $alert-transparent);
  }

  .btn:not(.btn-default) {
    background: rgba($white, 0.8);
    color: $success;
  }
}

.alert-info {
  background: $info;
  color: $white;

  &.alert-transparent {
    color: $info;
    background: rgba($info, $alert-transparent);
  }

  .btn:not(.btn-default) {
    background: rgba($white, 0.8);
    color: $info;
  }
}

.alert-warning {
  background: $warning;
  color: $white;

  &.alert-transparent {
    color: $warning;
    background: rgba($warning, $alert-transparent);
  }

  .btn:not(.btn-default) {
    background: rgba($white, 0.8);
    color: $warning;
  }
}

.alert-danger {
  background: $danger;
  color: $white;

  &.alert-transparent {
    color: $danger;
    background: rgba($danger, $alert-transparent);
  }

  .btn:not(.btn-default) {
    background: rgba($white, 0.8);
    color: $danger;
  }
}

///////// Breadcrumbs //////////
.documentationPage .breadcrumb {
  color: rgba(51, 51, 51, 0.6);
}

.documentationPage .breadcrumb .active {
  color: rgba(51, 51, 51, 0.9);
}

.breadcrumb {
  color: $content-color;
  margin-bottom: 1.5rem;
  margin-top: 0;

  > .active {
    color: $white;
    font-weight: $font-weight-semi-bold;
  }

  padding: 0;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: '>';
  color: $gray-600;
}

///////// Glyphicons //////////

.glyphicon {
  vertical-align: middle;
}

///////// Font Awesome //////////

.navbar {
  .fa {
    &.fa-lg {
      line-height: 19px;
    }
  }
}

///////// Tooltips //////////

.bs-tether-target.show {
  display: inline-block !important;
}

.tooltip.in {
  opacity: 1;
}

.tooltip-inner {
  @include border-radius(3px);
}

///////// Base Elements //////////

.navbar-notifications {
  .list-group-item:hover {
    .progress {
      background: $white;
    }
  }

  .btn-link {
    color: #666;
    text-decoration: none;

    &:hover {
      color: #333;
    }
  }
}

///////// List Group //////////

.list-group {
  .list-group-item {
    background-color: transparent;

    &:hover {
      background-color: $widget-bg-color;
    }
  }
}

.list-group-lg {
  .list-group-item {
    padding: 1.25rem;
  }
}

.list-group:not(.list-group-sortable) .list-group-item {
  border-left: none;
  border-right: none;
}

.list-group-item {
  @include transition(background 0.15s ease-in-out);

  &:first-child {
    border-radius: 0;
  }

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: $gray-100;
  }

  &,
  &:hover {
    color: $text-color;
  }
}

////////// Headings //////////

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  small,
  .small {
    font-weight: $font-weight-thin;
    font-size: 75%;
    color: #f4f4f580 !important;
  }
}

// Popovers

.popover {
  border: none;

  @include box-shadow(none);

  .popover-header {
    border-bottom: none;
    color: #000000;
  }
}

.popover-content {
  padding: 20px;
}

// Modal

.modal {
  color: $white;
  border-radius: 4px;

  &-dialog {
    margin: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-content {
    border-radius: 8px;
    border: none;
    box-shadow: none !important;
  }

  .modal-header {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    padding: 25px 30px;
    display: flex;
    justify-content: space-between;

    .modal-title {
      font-weight: 500;
      color: #fff;
    }

    border-bottom: 0 !important;
  }

  .modal-body {
    //background: #252544 !important;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    color: #fff !important;
  }

  .close {
    color: #fff;
    background-color: transparent !important;
    border: none !important;
  }
}

.modal-body {
  padding: 0 30px 25px 30px;
}

// Navs
// --------------------------------------------------

.nav-tabs {
  &.nav-item + .nav-item {
    margin-left: 0;
  }

  border-bottom: none;
  border-top-left-radius: $widget-border-radius;
  border-top-right-radius: $widget-border-radius;

  > .nav-item {
    margin-bottom: 0;

    > .nav-link {
      padding: 12px 18px;
      border: none;
      border-top-left-radius: $widget-border-radius;
      border-top-right-radius: $widget-border-radius;

      .label {
        margin-bottom: -2px;
      }

      @include transition(color 0.15s ease-in-out);

      &:hover {
        background-color: $nav-tabs-active-link-hover-color;
      }

      &.active {
        background: $widget-bg-color;
        color: $white;
      }
    }
  }
}

.tab-content {
  position: relative;
  z-index: 1;
  background-color: $widget-bg-color;
  border-bottom-left-radius: $widget-border-radius;
  border-bottom-right-radius: $widget-border-radius;

  > .tab-pane {
    padding: $spacer * 2;
  }
}

//
// Panels
// --------------------------------------------------

.panel {
  background-color: transparent;
  border: none;
}

.panel-header {
  background-color: $widget-bg-color;
  border-bottom: 1px solid transparent;

  @include border-top-radius(($widget-border-radius));
}

// Code
// -----------------

code {
  font-size: 85%;
  background-color: rgba($primary, 0.3);
  color: $success;
  padding: 2px;
  border-radius: $border-radius;
}

pre {
  display: block;
  padding: 6.5px;
  margin: 0 0 (1rem * 0.5);
  font-size: 13px;
  line-height: 1rem;
  word-break: break-all;
  word-wrap: break-word;
  color: $pre-color;
  background-color: $gray-100;
  border: 1px solid $gray-400;
  border-radius: $border-radius;
  white-space: pre-line; /* css-3 */

  // Account for some code outputs that place code tags in pre tags
  code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    white-space: pre-wrap;
    background-color: transparent;
    border-radius: 0;
  }
}

/* -------------------------
--------- Messenger --------
---------------------------- */

@-webkit-keyframes ui-spinner-rotate-right {
  /* line 64, ../../src/sass/messenger-spinner.scss */
  0% {
    -webkit-transform: rotate(0deg);
  }

  /* line 65, ../../src/sass/messenger-spinner.scss */
  25% {
    -webkit-transform: rotate(180deg);
  }

  /* line 66, ../../src/sass/messenger-spinner.scss */
  50% {
    -webkit-transform: rotate(180deg);
  }

  /* line 67, ../../src/sass/messenger-spinner.scss */
  75% {
    -webkit-transform: rotate(360deg);
  }

  /* line 68, ../../src/sass/messenger-spinner.scss */
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes ui-spinner-rotate-left {
  /* line 72, ../../src/sass/messenger-spinner.scss */
  0% {
    -webkit-transform: rotate(0deg);
  }

  /* line 73, ../../src/sass/messenger-spinner.scss */
  25% {
    -webkit-transform: rotate(0deg);
  }

  /* line 74, ../../src/sass/messenger-spinner.scss */
  50% {
    -webkit-transform: rotate(180deg);
  }

  /* line 75, ../../src/sass/messenger-spinner.scss */
  75% {
    -webkit-transform: rotate(180deg);
  }

  /* line 76, ../../src/sass/messenger-spinner.scss */
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes ui-spinner-rotate-right {
  /* line 80, ../../src/sass/messenger-spinner.scss */
  0% {
    -moz-transform: rotate(0deg);
  }

  /* line 81, ../../src/sass/messenger-spinner.scss */
  25% {
    -moz-transform: rotate(180deg);
  }

  /* line 82, ../../src/sass/messenger-spinner.scss */
  50% {
    -moz-transform: rotate(180deg);
  }

  /* line 83, ../../src/sass/messenger-spinner.scss */
  75% {
    -moz-transform: rotate(360deg);
  }

  /* line 84, ../../src/sass/messenger-spinner.scss */
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-moz-keyframes ui-spinner-rotate-left {
  /* line 88, ../../src/sass/messenger-spinner.scss */
  0% {
    -moz-transform: rotate(0deg);
  }

  /* line 89, ../../src/sass/messenger-spinner.scss */
  25% {
    -moz-transform: rotate(0deg);
  }

  /* line 90, ../../src/sass/messenger-spinner.scss */
  50% {
    -moz-transform: rotate(180deg);
  }

  /* line 91, ../../src/sass/messenger-spinner.scss */
  75% {
    -moz-transform: rotate(180deg);
  }

  /* line 92, ../../src/sass/messenger-spinner.scss */
  100% {
    -moz-transform: rotate(360deg);
  }
}

@keyframes ui-spinner-rotate-right {
  /* line 96, ../../src/sass/messenger-spinner.scss */
  0% {
    transform: rotate(0deg);
  }

  /* line 97, ../../src/sass/messenger-spinner.scss */
  25% {
    transform: rotate(180deg);
  }

  /* line 98, ../../src/sass/messenger-spinner.scss */
  50% {
    transform: rotate(180deg);
  }

  /* line 99, ../../src/sass/messenger-spinner.scss */
  75% {
    transform: rotate(360deg);
  }

  /* line 100, ../../src/sass/messenger-spinner.scss */
  100% {
    transform: rotate(360deg);
  }
}

@keyframes ui-spinner-rotate-left {
  /* line 104, ../../src/sass/messenger-spinner.scss */
  0% {
    transform: rotate(0deg);
  }

  /* line 105, ../../src/sass/messenger-spinner.scss */
  25% {
    transform: rotate(0deg);
  }

  /* line 106, ../../src/sass/messenger-spinner.scss */
  50% {
    transform: rotate(180deg);
  }

  /* line 107, ../../src/sass/messenger-spinner.scss */
  75% {
    transform: rotate(180deg);
  }

  /* line 108, ../../src/sass/messenger-spinner.scss */
  100% {
    transform: rotate(360deg);
  }
}

/* Messenger spinner */
.messenger-spinner {
  position: relative;
  border-radius: 100%;
}

/* line 120, ../../src/sass/messenger-spinner.scss */
ul.messenger.messenger-spinner-active .messenger-spinner .messenger-spinner {
  display: block;
}

/* line 124, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
}

/* line 130, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side .messenger-spinner-fill {
  border-radius: 999px;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
}

/* line 140, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side-left {
  left: 0;
}

/* line 143, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side-left .messenger-spinner-fill {
  left: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  -webkit-animation-name: ui-spinner-rotate-left;
  -moz-animation-name: ui-spinner-rotate-left;
  -o-animation-name: ui-spinner-rotate-left;
  animation-name: ui-spinner-rotate-left;
  -webkit-transform-origin: 0 50%;
  -moz-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  -o-transform-origin: 0 50%;
  transform-origin: 0 50%;
}

/* line 152, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side-right {
  left: 50%;
}

/* line 155, ../../src/sass/messenger-spinner.scss */
.messenger-spinner .messenger-spinner-side-right .messenger-spinner-fill {
  left: -100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-animation-name: ui-spinner-rotate-right;
  -moz-animation-name: ui-spinner-rotate-right;
  -o-animation-name: ui-spinner-rotate-right;
  animation-name: ui-spinner-rotate-right;
  -webkit-transform-origin: 100% 50%;
  -moz-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  -o-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}

/* ----------- React Bootstrap Table ----------- */
.react-bootstrap-table {
  .order {
    position: absolute;
  }
  tbody > tr:nth-child(odd) {
    background-color: $widget-bg-color;
  }
  thead {
    background-color: rgba(0, 0, 0, 0.14);
  }
}

.react-bootstrap-table .reset-expansion-style {
  background-color: transparent !important;
  padding: 0 !important;
  width: 100% !important;
  height: 100% !important;
}

.react-bootstrap-table .row-expansion-style {
  padding: 0px !important;
  width: 100% !important;
  height: 100% !important;
}

.react-bs-table {
  .react-bs-container-body {
    .table td {
      vertical-align: middle;

      &:focus {
        outline: none;
      }
    }
  }
}

.react-bs-table-tool-bar {
  padding-bottom: 15px;
}

.react-bs-table-search-form {
  width: 360px;
  margin-left: auto;
}

.table-hover tbody tr:hover {
  color: $white;
}

.widget table {
  color: $white;
}

.react-bs-table table {
  color: $white;
}

.react-bs-table-sizePerPage-dropdown {
  .caret {
    display: none;
  }
}

//
// Accordion
// --------------------------------------------------

.accordion-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .fa {
    transition: $transition-base;

    &.expanded {
      transform: rotate(180deg);
    }
  }
}

//
// Jumbotron
// ----------------

.jumbotron {
  @include border-radius($widget-border-radius);
}

//
// Font Awesome
// --------------------------------------------------

.circle {
  .fa {
    vertical-align: baseline;
  }
}

//  Easy Pie Chart

.easy-pie-chart-md,
.easy-pie-chart-lg {
  position: relative;
  display: inline-block;
  text-align: center;
  color: #798892;
}

.easy-pie-chart-md canvas,
.easy-pie-chart-lg canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.easy-pie-chart-md {
  line-height: 120px;
  height: 120px;
  width: 120px;
}

.easy-pie-chart-lg {
  line-height: 140px;
  height: 140px;
  width: 140px;
}

/*         Flot        */

.chart-tooltip {
  position: fixed;
  padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;
  border: 1px solid $gray-light;
  font-size: $font-size-mini;
  background-color: $white;
}

.input-group-text {
  color: $white;
}

// awessome checkboxes

div.abc-checkbox label {
  &::before {
    background: none;
    border-color: #aaa;
  }

  &::after {
    color: $awesome-checkbox-default-color;
  }
}

div.abc-radio label {
  &::before {
    background: none;
    border-color: #aaa;
  }

  &::after {
    background-color: $awesome-checkbox-default-color;
  }
}

.checkbox-dark input[type='checkbox']:checked + label {
  &::after {
    color: $gray-600;
  }
}

.datepicker {
  .rdtPicker {
    background: rgba(51, 51, 51, 0.85);

    .rdtDay:hover,
    .rdtMonth:hover,
    .rdtBtn:hover,
    .rdtActive,
    thead tr:first-child th:hover {
      background-color: rgba($widget-bg-color, 0.4);
    }
  }
}

/* MDE EDITOR */
.markdown-help,
.mde-header-item .fa {
  color: white !important;
}

.markdown-help-svg {
  fill: white !important;
}

.mde-preview-content {
  border-color: rgba(255, 255, 255, 0.3) !important;
}

///// Toastify /////
.Toastify {
  .Toastify__toast {
    font-family: Montserrat, sans-serif;
    border-radius: $border-radius;
    font-weight: bolder;

    top: 50px;

    &.Toastify__toast--success {
      & .Toastify__toast-body {
        color: $white;
      }

      border: 3px solid #3978f6 !important;
      background-color: rgba(57, 120, 246, 0.2);
    }

    &.Toastify__toast--warn {
      & .Toastify__toast-body {
        color: $white;
      }

      border: 3px solid #3978f6 !important;
      background-color: rgba(57, 120, 246, 0.2);
    }

    &.Toastify__toast--error {
      & .Toastify__toast-body {
        color: $white;
      }

      border: 3px solid #3978f6 !important;
      background-color: rgba(57, 120, 246, 0.2);
    }

    &.Toastify__toast--info {
      & .Toastify__toast-body {
        color: $white;
      }

      border: 3px solid #3978f6 !important;
      background-color: rgba(57, 120, 246, 0.2);
    }

    &-body {
      text-align: center;
      color: $violet_deep;

      button {
        border-color: $violet_deep;
        color: $violet_deep;

        &:hover {
          border-color: transparent;
        }
      }
    }
  }

  .notifications-close {
    height: $font-size-base;
    color: $violet_deep;

    &:hover {
      color: lighten($violet_deep, 10%);
    }
  }
}

//// React Tags Input ////
.react-tagsinput {
  &.react-tagsinput--focused {
    border-color: $input-focus-border-color;
  }

  .react-tagsinput-tag,
  .react-tagsinput-input {
    margin: 2px !important;
  }

  .react-tagsinput-tag {
    border-radius: $border-radius;
    background-color: $purple;
    color: white;
    border-color: darken($purple, 10%);
  }
}

//// Highcharts Width fix ////
.highcharts-container,
.highcharts-root {
  width: 100% !important;
}

.breadcrumb > .active {
  color: $text-color;
}

.btn-link {
  color: $primary;
}

pre code {
  color: $content-color;
}

.modal {
  .modal-body,
  .modal-header,
  .modal-footer {
    //background: #1b1e3c;
    color: $content-color;
  }

  .modal-header {
    border-bottom: 1px solid $bg-custom-dark;
  }

  .modal-footer {
    border-top: 1px solid $bg-custom-dark;
  }
}

.css-yk16xz-control {
  background-color: #040620 !important;
  border-color: #040620 !important;
}

.tab-content {
  overflow: visible;
  background-color: transparent;
}

.css-1n7v3ny-option {
  color: $white !important;
  background-color: $bg-custom-dark !important;

  &:active {
    background-color: #2684ff !important;
    color: $white;
  }
}

.css-26l3qy-menu {
  background-color: #333867 !important;
  z-index: 9999 !important;
}

.css-yt9ioa-option {
  color: $white !important;
}

.css-1uccc91-singleValue {
  color: $white !important;
}

.css-1pahdxg-control,
.css-15xv4ds-control {
  background-color: $bg-custom-dark !important;
  color: $white !important;
}

.css-15xv4ds-control {
  background-color: $bg-custom-dark !important;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  opacity: 1;
}

.table-header-wrapper {
  background-color: rgba(0, 0, 0, 0.14);
}

.table thead th {
  border: 0 !important;
}

.table th,
.table td {
  border: 0 !important;
  vertical-align: middle;
  color: #fff;
}

.query-builder {
  margin: 0 !important;
}

.qb-lite .group--header:hover .group--header .group--drag-handler,
.qb-lite .group--header:hover .group--header .group--actions,
.qb-lite .group--header:not(:hover) .group--drag-handler,
.qb-lite .group--header:not(:hover) .group--actions,
.qb-lite .rule_group:not(:hover) .group--drag-handler,
.qb-lite .rule_group:not(:hover) .group--actions {
  opacity: 1 !important;
}

.group {
  background: $widget-bg-color !important;
  border: 1px solid transparent !important;
}

.apexcharts-tooltip {
  color: black;
  border-color: transparent;
}

.page-link {
  background-color: transparent;
  color: #474e80;
}

.page-item.active .page-link {
  color: #fff;
  font-weight: 600;
  background-color: #474e80;
}

//.react-bs-table table td {
//  overflow: visible !important;
//}

.table-responsive {
  overflow: visible !important;
}

.react-bs-container-body {
  overflow: visible !important;
}

.react-datepicker-wrapper {
  display: block !important;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.css-1rhbuit-multiValue {
  background-color: #474d84 !important;
}

.css-12jo7m5 {
  color: #fff !important;
}

.group--conjunctions {
  display: flex;
  width: 50%;
  align-items: center;
  input {
    width: 20px;
    height: 60px;
  }
  label {
    margin-bottom: 0;
    margin-left: 10px;
    margin-right: 15px;
  }
}

.group--header {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.react-bootstrap-table th .order > .dropup > .caret {
  margin: 0px 0px -5px -8px !important;
}

.react-bootstrap-table th .order > .dropdown > .caret {
  margin: 0px 0px 10px 5px !important;
  transform: rotate(180deg);
}

.css-15xv4ds-control {
  background-color: $bg-custom-dark !important;
}

.css-1f2kj7e-control {
  background-color: $bg-custom-dark !important;
}

.dropdown-toggle::after {
  content: url('../images/arrow-ios-down.svg');
  background-repeat: no-repeat;
  background-position: right center;
  margin-top: -2px;
  height: 10px;
  padding-right: 0;
}

button#pageDropDown,
button.dropdown-toggle {
  display: flex;
}

.wizardActiveItem {
  background: #13152b !important;
  border: 0.5px solid #0046b1;
}
.col-form-label {
  color: #f4f4f5e6 !important;
}
