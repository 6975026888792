.my-project-cron-field > span {
  font-weight: bold;
}

.my-project-cron .my-project-cron-select > span {
  border-radius: 10px;
  color: #999999;
  background-color: #040620;
  margin-bottom: 3px;
}
.my-project-cron .my-project-cron-select > span > span :hover {
  color: #999999;
}

.my-project-cron .my-project-cron-select > div:nth-child(2) {
  border-radius: 10px;
}
