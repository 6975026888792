.filterContainer {
  display: block;
  overflow-x: scroll;
}

@media (min-width: 1920px) {
  .filterContainer {
    overflow-x: scroll;
  }
}

.containerNames {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 140px;
  &::-webkit-scrollbar {
    display: none;
  }
}

.item {
  margin-bottom: 3px;
  font-size: 16px;
}

.mainContainer {
  display: flex;
}

.statsWidget {
  height: 100px;
}

.upn {
  word-break: break-word;
  max-height: 80px;
  display: flex;
  overflow-y: scroll;
  flex-wrap: wrap;
}

.messageClassContainer {
  display: flex;
  width: 100%;
}

.messageClass {
  margin-right: 7px;
  display: inline-block;
}

.internalCustodiansTable {
  width: 100%;
}

.internalCustodiansTable tbody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 420px;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: start;
}

.internalCustodiansTable tr {
  display: flex;
  justify-content: space-between;
}

.internalCustodiansTable tr td:first-child {
  width: 25%;
}

.internalCustodiansTable tr th:first-child {
  width: 25%;
  padding-left: 45px;
}

.internalCustodiansTable tr td:nth-child(2) {
  width: 50%;
}

.internalCustodiansTable tr th:nth-child(2) {
  width: 50%;
  padding-left: 14px;
}

@media (min-width: 1920px) {
  .internalCustodiansTable tr th:nth-child(2) {
    width: 50%;
    padding-left: 18px;
  }
}

.internalCustodiansTable tr td:nth-child(3) {
  width: 25%;
  margin-right: 5px;
}

.internalCustodiansTable tr th:nth-child(3) {
  width: 30%;
  padding-left: 25px;
}

@media (min-width: 1920px) {
  .internalCustodiansTable tr th:nth-child(3) {
    width: 30%;
    padding-left: 45px;
  }
}

.legalHoldName {
  overflow: auto;
  height: 50px;
  margin-bottom: 0;
}

.legalHoldDescription {
  max-height: 90px;
  display: flex;
  overflow-y: scroll;
  flex-wrap: wrap;
}

.customIcon {
  margin-bottom: 3px;
  width: 25px;
  height: 25px;
  font-weight: bold;
}
