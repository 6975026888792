.container {
  position: relative;
  top: 20px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  min-height: 550px;
}

.refreshWidget {
  color: white;
  width: 600px;
  margin-left: 30px;
  height: 200px;
  margin-bottom: 30px;
}

.settingsHeader {
  font-weight: 600px;
  font-size: 24.5px;
  color: #f4f4f5e6;
  margin-bottom: 50px;
  font-family: Poppins, serif;
}

.refreshIntervalHeader {
  width: 500px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
