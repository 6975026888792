.errorMessage {
  color: #db2a34;
  font-size: 11.2px;
  margin-bottom: 5px;
  margin-top: 3px;
}

.wizardList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.customSelectItem {
  margin-bottom: 20px;
}

.modalHeader {
  display: flex;
  align-items: center;
  padding-bottom: 10px !important;
}

.popupTable {
  width: 70%;
  margin-left: 15%;
}

.popupTable tbody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 120px;
  overflow-y: auto;
  text-align: center;
}

.popupTable tr {
  display: flex;
  justify-content: space-between;
}

.popupTable tr td {
  width: 100%;
}

.popupTable tr th {
  width: 100%;
  text-align: center;
}

.popupTableContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupHeader {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.exportName {
  word-break: break-word;
  max-height: 90px;
  display: flex;
  overflow-y: scroll;
  flex-wrap: wrap;
}

.cardName {
  max-width: 90%;
  overflow-x: scroll;
  white-space: nowrap;
}
