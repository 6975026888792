.initial-tooltip {
  display: flex;
  justify-content: space-between;
  height: 80px;
}

.extended-tooltip {
  max-height: 150px;
  animation: grow 1s;
  color: white;
}

@keyframes grow {
  0% {
    height: 0;
  }
  100% {
    max-height: 150px;
  }
}
.contact-header {
  font-weight: bold;
  font-size: 12px;
  margin-top: 20px;
}

.info-container {
  display: flex;
}

.icons {
  width: 30%;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}
.icon {
  color: white;
  font-size: 1.5rem;
}
.person-details {
  width: 70%;
  display: flex;
  flex-direction: column;
}
.contact-info {
  margin-left: 15px;
  font-size: 12.25px;
}
