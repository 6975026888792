.linksGroupWrapper :global(.collapse ul),
.linksGroupWrapper :global(.collapsing ul),
.linksGroupWrapper :global(li > a):hover {
  background: #FFFBF8 !important;
}

.linksGroupWrapper > :global(li > a):focus {
  @media (min-width: 768px) {
    background: #FFF !important;
    outline: none;
  }
}

.linksGroupWrapper :global(li > a) {
  color: #495057 !important;
}
