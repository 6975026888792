@import '../../styles/app';

.root {
  background: var(--background-color);
  height: 100%;
  position: relative;
  left: 0;
  transition: left $sidebar-transition-time ease-in-out;
}

.sidebarOpen{
margin-left: $sidebar-width-open;
}

.wrap {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  left: 0;
  right: 0;
  transition: left $sidebar-transition-time ease-in-out;

  @media print {
    margin: 0;
  }

  @media (max-width: breakpoint-max(sm)) {
    margin-left: 0;
    left: 0;
  }

  :global(.sidebar-right) & {
    margin-left: 0;
    margin-right: $sidebar-width-open;
  }

  :global(.sidebar-hide) & {
    margin: 0;
  }
}

.content {
  position: relative;
  flex-grow: 1;
  // 20px for footer height
  padding: 25px $content-padding ($content-padding + 20px);

  @media (max-width: breakpoint-max(sm)) {
    padding: 20px 15px;
  }

  // hammers disallows text selection, allowing it for large screens
  @media (min-width: breakpoint-min(sm)) {
    user-select: auto !important;
  }
}

.contentFooter {
  margin: 15px 0;
  bottom: 15px;
  color: $text-muted;
}

.container {
  padding-right: 25px;
  position: relative;
  height: auto;
  margin-right: 25px;
}

.mainView {
  padding: 25px 5px 25px 40px;
}
