.contentSearchName {
  word-break: break-word;
  max-height: 90px;
  display: flex;
  overflow-y: scroll;
  flex-wrap: wrap;
}
.exclamationMark {
  margin-right: 10px;
  margin-bottom: -5px;
}
.cardName {
  max-width: 90%;
  overflow-x: scroll;
  white-space: nowrap;
  margin-left: 5px;
}
