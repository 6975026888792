.statsWidget {
  height: 100px;
}

.searchDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.query {
  width: 100%;
  min-height: 50px;
  max-height: 170px;
  overflow-y: scroll;
  word-break: break-word;
}

.scopeContainer {
  width: 90%;
  display: flex;
  flex-direction: column;
}
