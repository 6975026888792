@import '../styles/app';

.root {
  :global(.container) {
    @media (min-width: 1200px) {
      max-width: 1440px;
    }
  }
}

.content {
  background-color: #fffbf8 !important;
  padding: $navbar-height * 2 0 0 0 !important;
  min-height: 100vh;
  color: #32373b !important;

  & :global(.widget) {
    box-shadow: none !important;
    background: transparent !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    color: #32373b !important;
    h5 {
      color: #32373b !important;
    }
  }

  & :global(.tab-content) {
    background-color: #fff !important;
  }

  & :global(.nav-link.active) {
    background-color: #fff !important;
    color: #32373b !important;
  }
}

.header {
  position: fixed !important;
  width: 100vw;
  background-color: #fff !important;
  height: 80px !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 99;
}

.logo {
  left: 0;
  top: 0;
  color: #32373b !important;
}

.company {
  display: flex;
  position: fixed;
  bottom: 0;
  align-items: center;
  width: inherit;
  max-width: inherit;

  color: #495057;
  font-size: 11px;
  font-weight: 400;
  background-repeat: no-repeat;
  margin-bottom: 30px;
  background-size: contain;
  border-radius: 50px;
  padding: 0 25px;

  img {
    border-radius: 50%;
    margin-right: 10px;
    margin-top: -4px;
    width: 50px;
  }
}

.sidebar {
  @media (max-width: 767px) {
    position: relative;
    width: auto;
    background: transparent;
    border-right: 0;
    margin-bottom: 20px;
    height: auto !important;

    .nav {
      padding-top: 0;

      > div > li > a {
        padding-left: 0 !important;
      }
    }
  }

  @media (min-width: 768px) {
    transform: none;
    box-shadow: -100vw 0 0 100vw white;
    position: absolute;
    height: 100%;
    right: 0;
    background: #fff;
    top: -40px;
    padding-top: 22px;
    overflow-y: visible !important;
    max-width: 100%;
    width: 224px;

    .nav {
      position: fixed;
      width: inherit;
      max-width: inherit;
      overflow-y: visible;
      max-height: calc(100vh - 200px);
    }

    > ul {
      padding-top: 0;
    }
  }

  & :global(a) {
    font-weight: 400 !important;

    &[aria-current='page'] {
      color: theme-color('warning') !important;
    }
  }

  & :global(.link-wrapper) {
    > a > span {
      display: none !important;
    }

    > a {
      padding-left: 25px !important;
      font-weight: 600 !important;
    }
  }
}

.scrollSpy {
  color: #32373b;
}

.activeScrollSpy > a,
.activeScrollSpy > a:hover,
.activeScrollSpy > a:focus {
  color: $warning !important;
}
