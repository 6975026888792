.themeStyle333964 .modal-content {
  background-color: #1b1e3c !important ;
}

.themeStyle333964 .modal-content .btn.btn-secondary {
  background: transparent !important ;
}

.themeStyle333964 .modal-content .btn {
  background-color: #3978f6 !important ;
}

.themeStyle333964 .modal-content .secondary-btn {
  background-color: #474d84 !important ;
}

.themeStyle333964 .modal-content .danger-btn {
  background-color: #db2a34 !important ;
}

.themeStyle333964 .modal-content .dropdown-toggle {
  background: transparent !important ;
}

.themeStyle333964 .modal-content .dropdown-item:hover {
  background: #3978f6 !important ;
}

.themeStyle333964 .my-project-cron-clear-button {
  background-color: #3978f6 !important ;
  border: #3978f6 !important;
}

.themeStyle0A0417 .modal-content {
  background-color: #272239 !important ;
}

.themeStyle0A0417 .modal-content .btn.btn-secondary {
  background: transparent !important ;
}

.themeStyle0A0417 .modal-content .btn {
  background-color: #19277a !important ;
}

.themeStyle0A0417 .modal-content .secondary-btn {
  background-color: #5f598a !important ;
}

.themeStyle0A0417 .modal-content .danger-btn {
  background-color: #db2a34 !important ;
}

.themeStyle0A0417 .my-project-cron-clear-button {
  background-color: #19277a !important ;
  border: #19277a !important;
}

.themeStyle3846AA .modal-content {
  background-color: #444fa5 !important ;
}

.themeStyle3846AA .modal-content .btn.btn-secondary {
  background: transparent !important ;
}

.themeStyle3846AA .modal-content .btn {
  background-color: #6370be !important ;
}

.themeStyle3846AA .modal-content .secondary-btn {
  background-color: #8b8db9 !important ;
}

.themeStyle3846AA .modal-content .danger-btn {
  background-color: #db2a34 !important ;
}

.themeStyle3846AA .my-project-cron-clear-button {
  background-color: #6370be !important ;
  border: #6370be !important;
}

.themeStyle3390C3 .modal-content {
  background-color: #2e71a8 !important ;
}

.themeStyle3390C3 .modal-content .btn.btn-secondary {
  background: transparent !important ;
}

.themeStyle3390C3 .modal-content .btn {
  background-color: #065d8c !important ;
}

.themeStyle3390C3 .modal-content .secondary-btn {
  background-color: #528fc2 !important ;
}

.themeStyle3390C3 .modal-content .danger-btn {
  background-color: #db2a34 !important ;
}

.themeStyle3390C3 .my-project-cron-clear-button {
  background-color: #065d8c !important ;
  border: #065d8c !important;
}
