@import '../../../styles/app';
.reportsListName {
  word-break: break-word;
  max-height: 90px;
  display: flex;
  overflow-y: auto;
  flex-wrap: wrap;
}
.reportsListDescription {
  word-break: break-word;
  max-height: 90px;
  display: flex;
  overflow-y: auto;
  flex-wrap: wrap;
}
.deleteModalContainer {
  text-align: center;
}
.deleteModalFooter {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.deleteButton {
  margin-right: 20px;
}
.icon {
  color: white;
  width: 15px;
  height: 15px;
  margin-right: 10px;
}
.cardContainer {
  position: absolute;
  z-index: 10000;
  top: 100px;
  left: 300px;
  min-width: 250px;
  min-height: 150px;
  max-width: 400px;
  max-height: 250px;
  border: 3px solid $primary !important;
  background-color: #333867 !important;
  border-radius: 3px;
  color: #fff !important;
}
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 5px;
}
.subject {
  margin-left: 7px;
  overflow-x: scroll;
  margin-bottom: 5px;
}
.recipients {
  max-height: 150px;
  overflow-y: scroll;
  margin-left: 7px;
}
.recipientsList {
  max-height: 50px;
  overflow-y: scroll;
}
.parentRow {
  width: 100%;
  background-color: var(--expand-row-bg-color) !important;
}
.expandedRowContainer {
  width: 100%;
  min-height: 40px;
  max-height: 180px;
  display: flex;
  margin-top: 0px;
  overflow-y: scroll;
}
@media (min-width: 2560px) {
  .expandedRowContainer {
    width: 100%;
    min-height: 40px;
    max-height: 180px;
    display: flex;
    margin-top: 0px;
    overflow-y: scroll;
  }
}
.expandedRowHeader {
  padding-top: 10px;
  margin-left: 10px;
  font-weight: bold;
  font-size: 13px;
}
.detailsExpanded {
  font-weight: bold;
  margin-right: 5px;
  margin-bottom: 0;
  margin-left: 10px;
}
.recipitentItem {
  margin-right: 5px;
}

.tagList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-height: 100px;
  overflow-y: scroll;
  margin-left: 15px;
}

.contentContainer {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.emailPolicyTooltip {
  position: absolute;
  z-index: 10000;
  top: 170px;
  left: 270px;
  min-width: 250px;
  min-height: 150px;
  max-width: 400px;
  max-height: 180px;
  border: 3px solid $primary !important;
  background-color: #333867 !important;
  border-radius: 3px;
  color: #fff !important;
  display: flex;
  flex-direction: column;
}
