.wizardList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 15px;
}
.formBody {
  width: 100%;
  margin-top: 20px;
}
.filters {
  display: flex;
  justify-content: space-around;
}

.errorMessage {
  color: red;
  font-size: 11.2px;
  margin-bottom: 5px;
}

.query {
  min-height: 40px;
  max-height: 70px;
  background-color: #040620;
  display: flex;
  overflow-y: scroll;
  width: 100%;
  padding-top: 10px;
  padding-left: 10px;
  word-break: break-word;
}
.queryContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  overflow-y: hidden;
}
.statementContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 55px;
}
.addGroupContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 20%;
  height: 55px;
}
.statementDropdown {
  color: white;
  background-color: #040620;
  height: 37px;
  min-width: 50px;
  border: 1px solid #040620;
  margin-right: 10px;
  border-radius: 5px;
  width: 200px;
}
.addGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px !important;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  min-height: 60px;
  width: 97%;
}

.notOperator {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-top: 10px;
}
.ruleContainer {
  display: flex;
  overflow-x: scroll;
  width: 95%;
  margin-left: 10px;
}

.oneRule {
  display: flex;
  margin-right: 10px;
  margin-bottom: 10px;
  max-width: 300px;
}
.removeRule {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.ruleOperator {
  color: white;
  background-color: #040620;
  height: 35px;
  border: 1px solid #040620;
}
.mainContainer {
  margin-bottom: 20px;
  max-height: 340px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
}

.activeButton {
  background-color: #474d84 !important;
  border: 1px solid #474d84;
  color: white !important;
  height: 35px;
  width: 44px;
}
.inactiveButton {
  background-color: transparent !important;
  border: 1px solid #474d84;
  color: white !important;
  height: 35px;
  width: 44px;
}
.collapsedQuery {
  height: 40px;
  background-color: #040620;
  display: flex;
  overflow-y: scroll;
  align-items: center;
  justify-content: flex-start;
  min-width: 300px;
  padding: 5px;
  width: 80%;
  margin-left: 20px;
}
.card {
  margin-bottom: 15px;
  background-color: #1c1c34;
  padding-top: 10px;
  padding-bottom: 10px;
}
.checkboxInput {
  position: absolute;
  margin-top: 10px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
  border: 1px solid #3978fa;
  cursor: pointer;
}
.container input:disabled ~ .checkboxInput {
  background-color: gray;
  border: 1px solid gray;
  cursor: default;
}
.container input:checked ~ .checkboxInput {
  background-color: #2196f3;
  border: 1px solid #3978fa;
}
.checkboxInput:after {
  content: '';
  position: absolute;
  display: none;
}
.container input:checked ~ .checkboxInput:after {
  display: block;
}
.container .checkboxInput:after {
  left: 5px;
  top: 2px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.inputFilter {
  width: 100%;
}
.filtersContainer {
  display: flex;
  flex-direction: column;
  max-height: 250px;
  overflow-y: scroll;
}

.splitFields {
  position: absolute;
  margin-left: 31%;
  display: flex;
  align-items: center;
  z-index: 2000;
  height: 37px;
  padding: 8px 0px;
  margin-top: 20px;
}
.queryMainContainer {
  width: 80%;
  min-height: 120px;
  margin-bottom: 0px;
  margin-right: 15px;
}
.builderSwitch {
  color: #3978fa !important;
  text-decoration: none;
}
.container {
  display: block;
  position: relative;
  padding-top: 0;
  padding-left: 25px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.container input:disabled ~ .checkmark {
  background-color: gray !important;
  border: 1px solid gray !important;
  cursor: default;
}
.checkmark {
  position: absolute;
  margin-top: 2px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
  border: 1px solid #3978fa;
  cursor: pointer;
  &:disabled {
    background-color: grey;
  }
}
.container input:checked ~ .checkmark {
  background-color: #2196f3;
  border: 1px solid #3978fa;
}
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
.container input:checked ~ .checkmark:after {
  display: block;
}
.container .checkmark:after {
  left: 5px;
  top: 2px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.subjectFilter {
  margin-bottom: 4px;
  margin-left: 26px;
}
