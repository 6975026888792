@import '../../../../styles/app';

.attachment__item {
  border: 1px solid $gray;
  border-radius: 6px;
  padding: 8px 16px;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 30ms 30ms;
  margin-bottom: 16px;
  &:not(:last-child) {
    margin-right: 16px;
  }
  //&:hover {
  //  background-color: $primary;
  //  border-color: $primary;
  //  cursor: pointer;
  //}
  //&_active {
  //  @extend .attachment__item, :hover;
  //}
  &_disabled {
    opacity: 0.5;
  }
}

.emailWrapper {
  margin-top: 20px;
  padding: 0 16px;
  border: 1px solid $dark;
  border-radius: 6px;
}

.p1Body {
  font-size: 14px;
  margin-top: 20px;
}

.emailDate {
  padding-left: 5px;
  font-size: 16px;
  white-space: nowrap;
  font-weight: bold;
  font-family: 'Poppins' !important;
  font-style: normal;
  margin-top: 0;
  margin-bottom: 7px;
  margin-right: 17px;
}

.detailsSection {
  padding-right: 5px;
  font-weight: bold;
  text-align: center;
  font-family: 'Poppins' !important;
  font-style: normal;
  font-size: 14px;
}

.sender {
  text-align: right;
  margin-bottom: -5px;
  margin-top: -15px;
}
.tabPane {
  background: rgba(0, 0, 0, 0.24);
}
.unsupportedMessage {
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
.senderContainer {
  width: 95%;
  margin-bottom: 0;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  font-size: 14px;
}
.navItem {
  background: rgba(0, 0, 0, 0.24);
  padding-right: 3px;
  color: $white !important;
  &:hover {
    color: $white !important;
  }
}
.navLink {
  background: transparent !important;
  color: $white !important;
  font-family: 'Poppins' !important;
  &:hover {
    color: $white !important;
  }
}
.linkText {
  color: $white !important;
  font-family: 'Poppins' !important;
}

.searchNameContainer {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
}
@media (min-width: 2560px) {
  .searchNameContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
  }
}
.searchName {
  overflow-x: scroll;
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
  margin-right: 5px;
  margin-left: 5px;
  font-family: 'Poppins' !important;
  font-style: normal;
  padding-top: 9px;
}
.singleTag {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 5px;
  padding-left: 5px;
  font-size: 12px;
  height: 21px;
  margin-right: 10px;
}
.tagsContainer {
  width: 100%;
  display: flex;
  overflow: auto;
  white-space: nowrap;
}
.searchNameHeader {
  display: flex;
  white-space: nowrap;
  font-size: 14px;
  margin-top: 5px;
  font-family: 'Poppins' !important;
  font-style: normal;
}

.headerFont {
  font-family: 'Poppins' !important;
  font-style: normal;
}
.messageClass {
  font-family: 'Poppins' !important;
  font-style: normal;
  font-size: 14px;
}
.btnFont {
  font-size: 14px;
  font-family: 'Poppins' !important;
}
.queryContainer {
  padding-top: 5px;
  padding-bottom: 5px;
  overflow-y: scroll;
  max-height: 60px;
}

.aiContentContainer {
  min-height: 300px;
  max-height: 800px;
  overflow: scroll;
}
