@import '../../../../styles/app';

.list {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  height: 680px;
  margin-bottom: 50px;
}

.activeWidget {
  border: 3px solid $primary !important;
  background-color: transparentize($primary, 0.8);
}
